<template>
  <!-- BEGIN: Content-->
  <div class="app-content content ">
    <div class="content-overlay" />
    <div class="header-navbar-shadow" />
    <div class="content-wrapper">
      <div class="content-header row">
        <div class="content-header-left col-md-9 col-12 mb-2">
          <div class="row breadcrumbs-top">
            <div class="col-12">
              <h2 class="content-header-title mb-0">
                Assign and edit PC Members / {{ convo.id ? `${convo.title}` : '' }}<br>
              </h2>
            </div>
          </div>
        </div>
        <div class="content-header-right text-md-end col-md-3 col-12 d-md-block d-none">
          <div class="mb-1 breadcrumb-right">
            <a
              class="btn btn-icon btn-secondary"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Back to Promotion Call view"
              @click="$router.push({ name: 'admin.calls.promotion.view', params: { id: convo.id } })"
            ><i data-feather="chevron-left" /></a>
          </div>
        </div>
      </div>
      <div class="content-body content-body--senior-call">
        <div class="row">
          <div class="col-12">
            <div class="card card--table">
              <div
                class="card-body pb-0"
                :class="{'d-none': loading}"
              >
                <div
                  class="row"
                >
                  <div class="col-sm-4">
                    <h4><strong>{{ applicantsTotal }}</strong> ICREAs</h4>
                    <div class="form-check form-check-success form-switch d-flex align-items-center">
                      <input
                        id="publishContent"
                        v-model="hideAssigneds"
                        type="checkbox"
                        class="form-check-input"
                      >
                      <label
                        class="form-check-label"
                        for="publishContent"
                      ><small class="ms-1">Hide assigneds </small></label>
                    </div>
                  </div>
                  <div class="col-sm-3">
                    <div class="">
                      <label
                        for=""
                        class="form-label"
                      >Filter by area (PC Member)</label>
                      <v-select
                        v-model="area"
                        label="name"
                        :options="areas"
                        :get-option-key="option => option.id"
                        @input="filter"
                      />
                    </div>
                  </div>
                  <div class="col-sm-3">
                    <div class="">
                      <label
                        for=""
                        class="form-label"
                      >Filter by area (ICREA's)</label>
                      <v-select
                        v-model="area_researcher"
                        label="name"
                        :options="areas"
                        :get-option-key="option => option.id"
                        @input="filter"
                      />
                    </div>
                  </div>
                  <div class="col-sm-2">
                    <div class="">
                      <label
                        for=""
                        class="form-label"
                      >Filter by PC Member</label>
                      <v-select
                        v-model="pcMember"
                        label="name"
                        :options="members.filter(e => e.assigned)"
                        :get-option-key="option => option.id"
                        @input="filter"
                      />
                    </div>
                  </div>
                </div>
                <hr>
                <div
                  class="stats__evaluators"
                >
                  <ul class="list-group list-group-horizontal-sm">
                    <li
                      v-for="member in members.filter(e => e.assigned)"
                      :key="member.name"
                      class="list-group-item"
                    >
                      {{ member.name }}
                      <span class="badge badge-light-success ms-1">
                        {{ member.evaluations }}
                      </span>
                      <br>
                      <span
                        class="badge bg-light-secondary"
                      >
                        {{ member.areas.length > 0 ? member.areas[0].code : '-' }}
                      </span>
                      <span class="badge bg-light-secondary ms-1">
                        {{ member.erc ? member.erc.code : '-' }}
                      </span>
                    </li>
                  </ul>
                </div>
                <div
                  v-if="selectedItems.length > 0"
                  class="alert alert-warning mt-2"
                >
                  <div class="alert-body">
                    <div class="row align-items-center">
                      <div class="col-auto text-dark">
                        <strong>{{ selectedItems.length }}</strong> ICREAS selected and assigned to a PC Member:
                      </div>
                      <div class="col-auto">
                        <button
                          class="btn btn-dark"
                          @click="selectedItem = null, pcMembersAdd = [], $store.dispatch('modals/toggleInfoItemSheet', true)"
                        >
                          <template v-if="!loading">
                            Assign PC Members
                          </template>
                          <div
                            v-else
                            class="loading custom-loading"
                          >
                            <div class="effect-1 effects" />
                            <div class="effect-2 effects" />
                            <div class="effect-3 effects" />
                          </div>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <CompleteTable
                v-if="!loading && !loadingTable"
                :sorted-field="sortedField"
                :data="applicants"
                :sort-mapping="sortMapping"
                :title="'ICREAs'"
                :total="applicantsTotal"
                :fetch-path="'convo/promotionIcreas'"
                :filter-path="'convo/promotionIcreas'"
                :actions="true"
                :show-actions="false"
                @selectedItems="saveSelectedItems"
              >
                <template v-slot="{field}">
                  <td
                    class="text-end"
                  >
                    <span
                      class="dis-ib"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title="Edit area and group"
                    ><a
                      class="btn btn-icon rounded-circle btn-flat-dark"
                      @click="selectOneItem(field)"
                    ><i data-feather="edit-3" /></a></span>
                  </td>
                </template>
                <template #customfilters>
                  <div class="mb-1">
                    <label
                      for=""
                      class="form-label"
                    >Researcher</label>
                    <v-select
                      label="name"
                      :options="users"
                      :get-option-key="option => option.id"
                      @search="onSearch({ name: $event }, 'users/fetchFakeUsers')"
                      @input="saveFilter($event, 'user')"
                    />
                  </div>
                  <div class="mb-1">
                    <label
                      for=""
                      class="form-label"
                    >Area</label>
                    <v-select
                      label="name"
                      :options="areas"
                      :get-option-key="option => option.id"
                      @input="saveFilter($event, 'area_name')"
                    />
                  </div>
                  <div class="mb-1">
                    <label
                      for=""
                      class="form-label"
                    >ERC</label>
                    <v-select
                      label="name"
                      :options="ercs"
                      :get-option-key="option => option.id"
                      @input="saveFilter($event, 'erc')"
                      @search="onSearch({ name: $event }, 'erc/filter')"
                    />
                  </div>
                  <div class="mb-1">
                    <label
                      for=""
                      class="form-label"
                    >Institution</label><!-- Its a text field because some evaluators might not have parametrized the institution -->
                    <input
                      type="text"
                      class="form-control"
                      name="nameseniorcall"
                      @change="saveFilter($event.target.value, 'institution')"
                    >
                  </div>
                </template>
                <template #info-sheet-test>
                  <div class="offcanvas-body">
                    <h4 v-if="!selectedItem">
                      {{ selectedItems.length }} ICREAs selected
                    </h4>
                    <h4 v-else>
                      {{ selectedItem.name }}
                    </h4>
                    <hr>
                    <div class="mb-1">
                      <label
                        for=""
                        class="form-label"
                      >Filter by area or subarea</label>
                      <v-select
                        v-model="areaFilter"
                        label="name"
                        :options="areas"
                        :get-option-key="option => option.id"
                      />
                    </div>
                    <hr>

                    <div class="mb-1">
                      <h5>{{ filteredPcMembers.length }} PC Members</h5>
                      <ul
                        id="basic-list-group"
                        class="list-group"
                      >
                        <li
                          v-for="member in filteredPcMembers"
                          :key="member.name"
                          class="list-group-item"
                        >
                          <div class="d-flex">
                            <div class="more-info w-100">
                              <div class="form-check">
                                <input
                                  id="tableColumn1"
                                  v-model="pcMembersAdd"
                                  type="checkbox"
                                  class="form-check-input"
                                  :value="member.id"
                                  :checked="selectedItem && selectedItem.pc_members.filter(e => e.id == member.id).length > 0"
                                >
                                <label
                                  class="form-check-label d-block"
                                  for="tableColumn1"
                                >{{ member.name }}
                                  <span class="badge bg-light-success float-end ms-1">{{ member.evaluations }}</span>
                                  <span class="badge bg-light-secondary ms-1 float-end">{{ member.erc ? member.erc.code : '-' }}</span>
                                  <span class="badge bg-light-secondary float-end">{{ member.areas.length > 0 ? member.areas[0].code : '-' }}</span>
                                </label>
                              </div>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="offcanvas-footer mt-auto">
                    <button
                      type="button"
                      class="btn btn-success mb-1 d-grid w-100"
                      @click="syncMembers"
                    >
                      Assign
                    </button>
                  </div>
                </template>
              </CompleteTable>
              <div
                v-else
              >
                <div
                  class="loading"
                  style="margin-top:8rem"
                >
                  <div class="effect-1 effects" />
                  <div class="effect-2 effects" />
                  <div class="effect-3 effects" />
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
  <!-- END: Content-->
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import CompleteTable from '@/views/back/partials/components/CompleteTable.vue'

export default {
  components: {
    CompleteTable,
  },
  data() {
    return {
      actions: {},
      selectedItems: [],
      pcMembersAdd: [],
      loading: true,
      loadingTable: false,
      area: null,
      area_researcher: null,
      pcMember: null,
      hideAssigneds: false,
      sortedField: 'Name',
      defaultFields: [
        {
          name: 'Name',
          checked: true,
          order: 1,
        },
        {
          name: 'Area',
          checked: true,
          order: 2,
        },
        {
          name: 'Institution',
          checked: true,
          order: 3,
        },
        {
          name: 'Erc',
          checked: true,
          order: 4,
        },
        {
          name: 'Status',
          checked: true,
          order: 5,
        },
        {
          name: 'PC Members',
          checked: true,
          order: 6,
        },
      ],
      sortMapping: {
        Name: 'name',
        Area: 'areas',
        Erc: 'erc.code',
        Institution: 'host',
        Status: 'status',
        'PC Members': 'pc_members',
      },
      convoId: this.$route.params.id,
      selectedItem: null,
      areaFilter: null,
    }
  },
  computed: {
    ...mapGetters({
      convo: 'convo/convo',
      loggedUser: 'auth/admin',
      areas: 'areas/areas',
      members: 'pcMembers/items',
      fields: 'modals/tableSettingsFields',
      applicants: 'convo/applicants',
      applicantsTotal: 'convo/applicantsTotal',
      filters: 'filters/filters',
      users: 'users/fakeUsers',
      ercs: 'erc/items',
    }),
    filteredPcMembers() {
      return this.members.filter(e => e.assigned && (this.areaFilter ? (e.areas[0] && e.areas[0].id === this.areaFilter.id) : true))
    },
  },
  watch: {
    hideAssigneds() {
      this.filter()
    },
  },
  async mounted() {
    if (this.areas.length === 0) {
      this.$store.dispatch('areas/filter', this.$store.getters['filters/filters'])
    }

    if (this.convoId && this.convoId !== this.convo.id) {
      await this.$store.dispatch('convo/fetchSpecific', this.convoId)
    }

    this.reloadData()

    await this.$store.dispatch('modals/fetchUserFields', 'promotion_groups')
    if (!this.fields || this.fields.length === undefined) {
      // If user doesnt have default fields, load the true default
      await this.$store.dispatch('modals/fetchFields', {
        fields: this.defaultFields,
        table: 'promotion_groups',
      })
    }

    feather.replace({
      width: 14,
      height: 14,
    })
  },
  methods: {
    async reloadData() {
      this.saveSelectedItems([])
      this.selectedItem = null
      this.pcMembersAdd = []
      await this.$store.dispatch('convo/promotionIcreas', {
        ...this.filters,
        hideAssigneds: this.hideAssigneds,
        area_name: this.area,
        pcMember: this.pcMember,
      })
      await this.$store.dispatch('pcMembers/filter', { pcmember_convo: this.convo.id })
      this.loading = false
    },
    async syncMembers() {
      if (this.selectedItems.length > 0 || this.selectedItem) {
        Vue.swal({
          title: 'Do you want to assign this PC Members to the selected ICREAs?',
          html: '',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'Yes, assign!',
        }).then(async result => {
          this.loading = true
          if (result.isConfirmed) {
            let itemIds = []
            if (this.selectedItem) {
              itemIds = [this.selectedItem.id]
            } else {
              itemIds = this.selectedItems.map(el => el.id)
            }
            await this.$store.dispatch('pcMembers/assignPcMembers', {
              pcMembersAdd: this.pcMembersAdd, itemIds, convoId: this.convo.id, singleUser: this.selectedItem != null,
            })
            this.reloadData()
            this.$store.dispatch('modals/toggleInfoItemSheet', false)
            Vue.swal('PC Members assigned successfully!', '', 'success')
          }
        })
      } else {
        this.$toastr.error('', 'No items selected!')
      }
    },
    updateCallStatus() {
      this.$store.dispatch('users/updateStatus', { id: this.loggedUser.id, status: this.loggedUser.call_status })
    },
    saveSelectedItems(items) {
      this.selectedItems = items
    },
    async filter() {
      this.loadingTable = true
      await this.$store.dispatch('pagination/clearPage')

      await this.saveFilter(this.area, 'area')
      await this.saveFilter(this.hideAssigneds, 'hideAssigneds')
      await this.saveFilter(this.area_researcher, 'area_name')
      await this.saveFilter(this.pcMember, 'pcMember')

      await this.$store.dispatch('convo/promotionIcreas', this.filters)

      this.loadingTable = false
    },
    selectOneItem(field) {
      this.selectedItem = field
      this.$store.dispatch('modals/toggleInfoItemSheet', true)
      this.pcMembersAdd = field.pc_members.map(e => e.id)
    },
    async onSearch(search, store) {
      if (search.name !== '' && search.name.length > 2) {
        await this.$store.dispatch(store, search)
      }
    },
    saveFilter(value, field) {
      this.$store.dispatch('filters/saveFilters', {
        value,
        field,
      })
    },
  },
}
</script>
